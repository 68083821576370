import { AxiosError, AxiosInstance } from 'axios';
import { User } from '@/common/types.ts';

export type UserAPIResponse = {
  username: string;
  email: string;
  id: number;
  permissions: string[];
};

export type UserLoginAPIRequestBody = {
  email: string;
  password: string;
};

export type UserLoginAPIResponse = {
  token: string;
};

export async function login(
  axios: AxiosInstance,
  credentials: UserLoginAPIRequestBody
): Promise<{
  token: string;
}> {
  return (await axios.post<UserLoginAPIResponse>('/auth/login/', credentials)).data;
}

export async function getUser(axios: AxiosInstance, token?: string): Promise<User> {
  const config = token ? { headers: { Authorization: `Token ${token}` } } : {};
  const user = (await axios.get<UserAPIResponse>('/auth/me/', config)).data;
  let workingGroup = null;
  try {
    workingGroup = (await axios.get<{ id: number; name: string }>(`/auth/working-group/`, config))
      .data;
  } catch (error) {
    if ((error! as AxiosError).response?.status === 404) {
      // pass
    } else {
      throw error;
    }
  }
  return {
    username: user.username,
    email: user.email,
    id: user.id,
    permissions: user.permissions,
    workingGroup: workingGroup
  };
}

export async function logout(axios: AxiosInstance): Promise<void> {
  return await axios.post('/auth/logout/');
}

export type ChangePasswordAPIRequestBody = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

export async function changePassword(
  axios: AxiosInstance,
  credentials: ChangePasswordAPIRequestBody
): Promise<void> {
  return await axios.post('/auth/change-password/', credentials);
}
