import { Link, useRouterState } from '@tanstack/react-router';
import { userCanAccessAdminPanel } from '@/common/auth-utils.ts';
import { useAuth } from '@/provider/auth.tsx';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import logo from '@/assets/logo.png';

export default function NavBar() {
  const { isAuthenticated, user } = useAuth();
  const { i18n, t } = useTranslation('navigation');
  const routerState = useRouterState();

  const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
    void i18n.changeLanguage(event.target.value);
  };

  return (
    <nav className="bg-gray-200 p-2 border-b border-gray-400 shadow-md flex flex-row justify-between items-center">
      <div className="flex flex-row gap-4 items-center">
        <Link
          to="/"
          search={{
            q: '',
            p: 1,
            f: []
          }}
          activeProps={{
            className: 'underline'
          }}>
          <img src={logo} alt="logo" className="h-12" />
        </Link>
        <Link
          to="/"
          search={{
            q: '',
            p: 1,
            f: []
          }}
          activeOptions={{
            exact: true,
            includeSearch: false
          }}
          activeProps={{
            className: 'underline'
          }}>
          {t('compound_search.display')}
        </Link>
      </div>
      <div className="flex flex-row gap-4 items-center">
        {isAuthenticated && userCanAccessAdminPanel(user) ? (
          <Link
            to="/admin"
            search={{}}
            activeOptions={{
              includeSearch: false
            }}
            activeProps={{
              className: 'underline'
            }}>
            {t('admin.display')}
          </Link>
        ) : null}
        {isAuthenticated ? (
          <Link to="/settings" search={{}}>
            {t('settings.display')}
          </Link>
        ) : null}
        {!isAuthenticated ? (
          <Link
            to="/login"
            search={{ redirect: routerState.location.href }}
            activeProps={{
              className: 'underline'
            }}>
            {t('login.display')}
          </Link>
        ) : null}
        {isAuthenticated ? (
          <Link to="/logout" search={{}}>
            {t('logout.display')}
          </Link>
        ) : null}
        <select value={i18n.language} onChange={handleLanguageChange}>
          <option value={'en'}>{t('languages.english.display', { ns: 'common' })}</option>
          <option value={'de'}>{t('languages.german.display', { ns: 'common' })}</option>
        </select>
      </div>
    </nav>
  );
}
