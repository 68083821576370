import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createAssay,
  CreateAssayValues,
  getAssay,
  getAssays,
  updateAssay,
  UpdateAssayValues
} from '@/services/api/assay.ts';
import { useAxios } from '@/provider/axios.tsx';

export function useAssays(page: number, search: string, onlyOwned: boolean, itemsPerPage?: number) {
  const { axios } = useAxios();
  return useQuery({
    queryKey: [
      'assays',
      { search: search, page: page, onlyOwned: onlyOwned, itemsPerPage: itemsPerPage },
      axios
    ],
    queryFn: () => getAssays(axios, page, search, onlyOwned, { itemsPerPage: itemsPerPage })
  });
}

export function useAssay(id: number) {
  const { axios } = useAxios();
  return useQuery({
    queryKey: ['assays', { id }, axios],
    queryFn: () => getAssay(axios, id)
  });
}

export function useCreateAssay() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: CreateAssayValues) => createAssay(axios, values),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['assays'] });
    }
  });
}

export function useUpdateAssay() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { id: number; values: UpdateAssayValues }) =>
      updateAssay(axios, data.id, data.values),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['assays'] });
    }
  });
}

export function useDeleteAssay() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => axios.delete(`/assays/${id}/`),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['assays'] });
    }
  });
}
