import { useField } from 'formik';
import StyledTextInput from '@/partials/form/styledTextInput.tsx';
import { ReactNode } from 'react';

export default function StyledFormikTextInput({
  label,
  ...props
}: {
  label: ReactNode;
  name: string;
  id: string;
  help?: string;
  [key: string]: unknown;
}) {
  const [field, meta] = useField(props);
  return (
    <div className="flex flex-col">
      <label htmlFor={props.id}>{label}</label>
      <StyledTextInput {...field} {...props} />
      {props.help ? <div className="text-sm text-gray-500">{props.help}</div> : null}
      {meta.touched && meta.error ? (
        <div className="text-sm text-danger-500 whitespace-pre">{meta.error}</div>
      ) : null}
    </div>
  );
}
