import { ValidateExcelFileAPIResponse } from '@/services/api/excel.ts';
import { useTranslation } from 'react-i18next';

function compoundErrorToTranslatedString(
  error: string,
  t: (key: string, options?: Record<string, unknown>) => string
) {
  switch (error) {
    case 'invalid_smiles':
      return t('excel-file.validate.result.error.invalid_smiles');
    case 'missing_structure':
      return t('excel-file.validate.result.error.missing_structure');
    case 'missing_mol_id':
      return t('excel-file.validate.result.error.missing_mol_id');
    default:
      return error;
  }
}

function generateCompoundWarningsTranslated(
  compound: ValidateExcelFileAPIResponse['compounds'][0],
  t: (key: string, options?: Record<string, unknown>) => string
) {
  const warnings: Array<string> = [];
  if (compound.moleculeId === null) {
    warnings.push(t('excel-file.validate.result.warning.new_molecule'));
  }
  if (compound.synonymId === null) {
    warnings.push(t('excel-file.validate.result.warning.new_synonym'));
  }
  return warnings.map((warning, index) => {
    return <li key={index}>{warning}</li>;
  });
}

export function ExcelFileValidationCompoundResult({
  data
}: {
  data: ValidateExcelFileAPIResponse['compounds'];
}) {
  const { t } = useTranslation('compounds');

  if (data.length === 0) {
    return <div>{t('excel-file.validate.result.no_compounds')}</div>;
  }
  return (
    <table className="table table-auto w-full break-all">
      <thead>
        <tr className="border-y border-y-gray-400">
          <th className="px-2 text-left border-x">
            {t('excel-file.validate.result.compound.index')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.compound.valid')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.compound.mol_id')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.compound.structure')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.compound.warnings')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.compound.errors')}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((compound, index) => {
          return (
            <tr
              key={index + compound.molId}
              className={
                'border-b border-b-gray-400 break-all' + (index % 2 == 0 ? ' bg-gray-50' : '')
              }>
              <td className="px-2 border-x align-text-top">{index + 1}</td>
              <td
                className={
                  'px-2 border-e align-text-top ' +
                  (compound.valid ? 'text-success-600' : 'text-danger-600')
                }>
                {compound.valid
                  ? t('excel-file.validate.result.is_valid')
                  : t('excel-file.validate.result.is_invalid')}
              </td>
              <td className="px-2 border-e align-text-top">{compound.molId}</td>
              <td className="px-2 border-e align-text-top">{compound.structure}</td>
              <td className="px-2 border-e align-text-top text-warning-700">
                <ul className="list-disc list-inside">
                  {generateCompoundWarningsTranslated(compound, t)}
                </ul>
              </td>
              <td className="px-2 border-e align-text-top text-danger-600">
                <ul className="list-disc list-inside">
                  {compound.errors.map((error, index) => {
                    return <li key={error + index}>{compoundErrorToTranslatedString(error, t)}</li>;
                  })}
                </ul>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
