import { ReactNode } from 'react';

export default function StyledForm({
  children,
  styled = true,
  ...props
}: {
  children: ReactNode;
  styled?: boolean;
  [key: string]: unknown;
}) {
  let className = 'flex flex-col space-y-4';
  if (styled) {
    className += ' p-4 bg-gray-200 rounded-md border border-gray-300';
  }

  return (
    <form {...props} className={className}>
      {children}
    </form>
  );
}
