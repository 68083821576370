import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanDeactivateWorkingGroupMember } from '@/common/auth-utils.ts';

export const Route = createFileRoute('/admin/working-group/member/$memberId/deactivate')({
  beforeLoad: ({ context }) => {
    if (!userCanDeactivateWorkingGroupMember(context.auth.user)) {
      throw redirectToHome();
    }
  }
});
