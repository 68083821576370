import { User } from '@/common/types.ts';
import { redirect } from '@tanstack/react-router';

export function userIsAuthenticated(user: User | null) {
  return user !== null;
}

export function userCanAccessAdminPanel(user: User | null) {
  return (
    userCanAccessWorkingGroupPanel(user) ||
    userCanAccessAssayPanel(user) ||
    userCanAccessTargetPanel(user) ||
    userCanAccessExcelFilePanel(user) ||
    userCanAccessCompoundPanel(user) ||
    userCanAccessActivityPanel(user)
  );
}

export function userCanAddWorkingGroupMember(user: User | null) {
  return user?.permissions.includes('accounts.add_user') ?? false;
}

export function userCanDeactivateWorkingGroupMember(user: User | null) {
  return user?.permissions.includes('accounts.deactivate_user') ?? false;
}

export function userCanViewWorkingGroupMember(user: User | null) {
  return user?.permissions.includes('accounts.view_user') ?? false;
}

export function userCanEditWorkingGroupMember(user: User | null) {
  return user?.permissions.includes('accounts.change_user') ?? false;
}

export function userCanAccessWorkingGroupPanel(user: User | null) {
  return userCanViewWorkingGroupMember(user);
}

export function userCanAddAssay(user: User | null) {
  return user?.permissions.includes('compounds.add_assay') ?? false;
}

export function userCanEditAssay(user: User | null) {
  return user?.permissions.includes('compounds.change_assay') ?? false;
}

export function userCanDeleteAssay(user: User | null) {
  return user?.permissions.includes('compounds.delete_assay') ?? false;
}

export function userCanAccessAssayPanel(user: User | null) {
  // return userCanAddAssay(user) || userCanEditAssay(user) || userCanDeleteAssay(user);
  return user !== null;
}

export function userCanAddTarget(user: User | null) {
  return user?.permissions.includes('compounds.add_target') ?? false;
}

export function userCanEditTarget(user: User | null) {
  return user?.permissions.includes('compounds.change_target') ?? false;
}

export function userCanDeleteTarget(user: User | null) {
  return user?.permissions.includes('compounds.delete_target') ?? false;
}

export function userCanAccessTargetPanel(user: User | null) {
  // return userCanAddTarget(user) || userCanEditTarget(user) || userCanDeleteTarget(user);
  return user !== null;
}

export function userCanAddExcelFile(user: User | null) {
  if (user === null) {
    return false;
  }
  if (user.workingGroup === null) {
    return false;
  }
  return user.permissions.includes('compounds.add_excelsheet');
}

export function userCanDeleteExcelFile(user: User | null) {
  if (user === null) {
    return false;
  }
  if (user.workingGroup === null) {
    return false;
  }
  return user.permissions.includes('compounds.delete_excelsheet');
}

export function userCanEditExcelFile(user: User | null) {
  if (user === null) {
    return false;
  }
  if (user.workingGroup === null) {
    return false;
  }
  return user.permissions.includes('compounds.change_excelsheet');
}

export function userCanValidateExcelFile(user: User | null) {
  if (user === null) {
    return false;
  }
  if (user.workingGroup === null) {
    return false;
  }
  return user.permissions.includes('compounds.change_excelsheet');
}

export function userCanImportExcelFile(user: User | null) {
  if (user === null) {
    return false;
  }
  if (user.workingGroup === null) {
    return false;
  }
  return user.permissions.includes('compounds.change_excelsheet');
}

export function userCanViewExcelFile(user: User | null) {
  return user !== null;
}

export function userCanAccessExcelFilePanel(user: User | null) {
  return (
    userCanAddExcelFile(user) ||
    userCanDeleteExcelFile(user) ||
    userCanEditExcelFile(user) ||
    userCanValidateExcelFile(user) ||
    userCanImportExcelFile(user) ||
    userCanViewExcelFile(user)
  );
}

export function userCanAccessCompoundPanel(user: User | null) {
  return user !== null;
}

export function userCanAddActivity(user: User | null) {
  return user?.permissions.includes('compounds.add_activity') ?? false;
}

export function userCanEditActivity(user: User | null) {
  return user?.permissions.includes('compounds.change_activity') ?? false;
}

export function userCanDeleteActivity(user: User | null) {
  return user?.permissions.includes('compounds.delete_activity') ?? false;
}

export function userCanAccessActivityPanel(user: User | null) {
  return user !== null;
}

export function redirectToHome() {
  return redirect({
    to: '/',
    search: {
      q: '',
      f: [],
      p: 1
    }
  });
}

export function userHasWorkingGroupId(user: User | null, workingGroupId: number) {
  return user?.workingGroup?.id === workingGroupId;
}
