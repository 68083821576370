import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export function handeDefaultErrors(
  error: AxiosError,
  t: (key: string, options?: Record<string, unknown>) => string
) {
  if (error.response?.status === 403) {
    toast(t('error.forbidden', { ns: 'common' }), { type: 'error' });
    return true;
  }
  if (error.response?.status === 404) {
    toast(t('error.not_found', { ns: 'common' }), { type: 'error' });
    return true;
  }
  if (error.response?.status === 401) {
    toast(t('error.not_authenticated', { ns: 'common' }), { type: 'error' });
    return true;
  }

  return false;
}
