import { useField, useFormikContext } from 'formik';
import StyledTextInput from '@/partials/form/styledTextInput.tsx';
import { FormEvent } from 'react';

export default function StyledFormikFileUpload({
  label,
  ...props
}: {
  label: string;
  name: string;
  id: string;
  [key: string]: unknown;
}) {
  const [field, meta] = useField(props);
  const formikContext = useFormikContext();
  const { onChange, onBlur, value, ...rest } = field; // eslint-disable-line @typescript-eslint/no-unused-vars
  return (
    <div className="flex flex-col">
      <label htmlFor={props.id}>{label}</label>
      <StyledTextInput
        {...rest}
        {...props}
        type="file"
        onChange={(e: FormEvent<HTMLInputElement>) => {
          if (e.currentTarget.files) {
            void formikContext.setFieldValue(field.name, e.currentTarget.files[0]);
          }
        }}
      />
      {meta.touched && meta.error ? (
        <div className="text-sm text-danger-500">{meta.error}</div>
      ) : null}
    </div>
  );
}
