import StyledButton from '@/partials/form/styledButton.tsx';
import { ReactNode } from 'react';
import { useFormikContext } from 'formik';

export default function StyledFormikButton({
  children,
  ...props
}: {
  children: ReactNode;
  [key: string]: unknown;
}) {
  const { isSubmitting } = useFormikContext();
  return (
    <StyledButton {...props} disabled={isSubmitting}>
      {children}
    </StyledButton>
  );
}
