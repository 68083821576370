import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanAddActivity } from '@/common/auth-utils.ts';

export const Route = createFileRoute('/admin/activity/create')({
  beforeLoad: ({ context }) => {
    if (!userCanAddActivity(context.auth.user)) {
      throw redirectToHome();
    }
  }
});
