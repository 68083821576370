import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanEditActivity } from '@/common/auth-utils.ts';

export const Route = createFileRoute('/admin/activity/$activityId/edit')({
  beforeLoad: ({ context }) => {
    if (!userCanEditActivity(context.auth.user)) {
      throw redirectToHome();
    }
  }
});
