import { createFileRoute, useRouter } from '@tanstack/react-router';
import { redirectToHome, userCanAddExcelFile } from '@/common/auth-utils.ts';
import ExcelFileForm, { ExcelFileFormValues } from '@/components/forms/excel-file-form.tsx';
import { FormikHelpers } from 'formik';
import { useCreateExcelFile } from '@/services/query/excel.ts';
import { useTranslation } from 'react-i18next';
import H1 from '@/partials/common/h1.tsx';
import { DefaultLinkClass } from '@/partials/common/link.ts';
import Page from '@/partials/page/default.tsx';
import { AxiosError, isAxiosError } from 'axios';
import { handeDefaultErrors } from '@/services/query/utils.tsx';
import { toast } from 'react-toastify';

export const Route = createFileRoute('/admin/excel/create')({
  component: AdminExcelFileCreate,
  beforeLoad: ({ context }) => {
    if (!userCanAddExcelFile(context.auth.user)) {
      throw redirectToHome();
    }
  }
});

function AdminExcelFileCreate() {
  const createExcelFile = useCreateExcelFile();
  const { t } = useTranslation('compounds');

  const router = useRouter();

  const handleFormSubmit = (
    values: ExcelFileFormValues,
    { setSubmitting, setFieldError }: FormikHelpers<ExcelFileFormValues>
  ) => {
    if (values.file === null) {
      setFieldError('file', t('errors.required', { ns: 'form' }));
      setSubmitting(false);
      return;
    }
    createExcelFile.mutate(
      { file: values.file!, filename: values.filename },
      {
        onError: (error) => {
          if (isAxiosError(error)) {
            const axiosError = error as AxiosError<{ code?: string }>;
            if (handeDefaultErrors(axiosError, t)) {
              return;
            }
          }
          toast(t('excel-file.create.error.unknown'), { type: 'error' });
          setSubmitting(false);
        },
        onSuccess: () => {
          toast(t('excel-file.create.success'), { type: 'success' });
          router.history.push('/admin/excel');
        }
      }
    );
  };

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <H1>{t('excel-file.create.title.display')}</H1>
        <div>
          <a className={DefaultLinkClass} onClick={() => router.history.back()}>
            {t('Back', { ns: 'common' })}
          </a>
        </div>
      </div>
      <ExcelFileForm onSubmit={handleFormSubmit} />
    </Page>
  );
}
