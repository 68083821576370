import { createFileRoute, useRouter } from '@tanstack/react-router';
import { redirectToHome, userCanAddTarget } from '@/common/auth-utils.ts';
import Page from '@/partials/page/default.tsx';
import H1 from '@/partials/common/h1.tsx';
import { useTranslation } from 'react-i18next';
import { DefaultLinkClass } from '@/partials/common/link.ts';
import TargetForm, { TargetFormValues } from '@/components/forms/target-form.tsx';
import { useCreateTarget } from '@/services/query/target.ts';
import { toast } from 'react-toastify';
import { FormikHelpers } from 'formik';
import { AxiosError, isAxiosError } from 'axios';
import { handeDefaultErrors } from '@/services/query/utils.tsx';

export const Route = createFileRoute('/admin/target/create')({
  component: AdminTargetCreate,
  beforeLoad: ({ context }) => {
    if (!userCanAddTarget(context.auth.user)) {
      throw redirectToHome();
    }
  }
});

function AdminTargetCreate() {
  const { t } = useTranslation('compounds');
  const router = useRouter();

  const createTarget = useCreateTarget();

  const handleFormSubmit = (
    values: TargetFormValues,
    { setSubmitting }: FormikHelpers<TargetFormValues>
  ) => {
    createTarget.mutate(values, {
      onError: (error) => {
        if (isAxiosError(error)) {
          const axiosError = error as AxiosError<{ code?: string }>;
          if (handeDefaultErrors(axiosError, t)) {
            return;
          }
        }
        toast(t('target.create.error.unknown'), { type: 'error' });
        setSubmitting(false);
      },
      onSuccess: () => {
        console.debug('Target created');
        toast(t('target.create.success'), { type: 'success' });
        router.history.push('/admin/target');
      }
    });
  };

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <H1>{t('target.create.title.display')}</H1>
        <div>
          <a className={DefaultLinkClass} onClick={() => router.history.back()}>
            {t('Back', { ns: 'common' })}
          </a>
        </div>
      </div>
      <TargetForm onSubmit={handleFormSubmit} buttonText={t('Create', { ns: 'common' })} />
    </Page>
  );
}
