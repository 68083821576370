import { ReactNode } from 'react';

export default function StyledButton({
  variant = 'primary',
  children,
  size = 'md',
  disabled = false,
  extraClasses = '',
  ...props
}: {
  variant?: 'primary' | 'secondary' | 'success' | 'danger';
  children: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  extraClasses?: string;
  [key: string]: unknown;
}) {
  let className = 'rounded-md';
  switch (variant) {
    case 'primary':
      className += ' bg-primary-700 text-white disabled:bg-gray-400 hover:bg-primary-800';
      break;
    case 'danger':
      className += ' bg-danger-600 text-white disabled:bg-gray-400 hover:bg-danger-700';
      break;
    case 'secondary':
      className += ' bg-gray-300 text-gray-800 disabled:bg-gray-400 hover:bg-gray-400';
      break;
  }
  switch (size) {
    case 'sm':
      className += ' text-sm px-2 py-1';
      break;
    case 'md':
      className += ' text-base px-4 py-2';
      break;
    case 'lg':
      className += ' text-lg px-6 py-3';
      break;
  }

  className += ` ${extraClasses}`;

  return (
    <button className={className} {...props} disabled={disabled}>
      {children}
    </button>
  );
}
