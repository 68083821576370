import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanAccessExcelFilePanel } from '@/common/auth-utils.ts';

type SearchParams = {
  search: string;
  page: number;
  showHidden: boolean;
};

export const Route = createFileRoute('/admin/excel')({
  beforeLoad: ({ context }) => {
    if (!userCanAccessExcelFilePanel(context.auth.user)) {
      throw redirectToHome();
    }
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      search: (search.search as string) || '',
      page: (search.page as number) || 1,
      showHidden: (search.showHidden as boolean) ?? false
    };
  }
});
