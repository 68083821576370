import { createFileRoute, useRouter } from '@tanstack/react-router';
import Page from '@/partials/page/default.tsx';
import { Loading } from '@/partials/common/loading.tsx';
import { redirectToHome, userCanImportExcelFile } from '@/common/auth-utils.ts';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImportExcelFile } from '@/services/query/excel.ts';
import { AxiosError, isAxiosError } from 'axios';
import { handeDefaultErrors } from '@/services/query/utils.tsx';
import { toast } from 'react-toastify';
import H1 from '@/partials/common/h1.tsx';
import { DefaultLinkClass } from '@/partials/common/link.ts';

export const Route = createFileRoute('/admin/excel/$excelId/import')({
  component: AdminExcelFileImport,
  beforeLoad: ({ context }) => {
    if (!userCanImportExcelFile(context.auth.user)) {
      throw redirectToHome();
    }
  }
});

function AdminExcelFileImport() {
  const { t } = useTranslation('compounds');
  const router = useRouter();
  const { excelId } = Route.useParams();
  const [status, setStatus] = useState<'loading' | 'error' | 'success'>('loading');

  const importExcelFile = useImportExcelFile();

  useEffect(() => {
    importExcelFile.mutate(parseInt(excelId, 10), {
      onSuccess: () => {
        setStatus('success');
      },
      onError: (error) => {
        setStatus('error');
        if (isAxiosError(error)) {
          const axiosError = error as AxiosError<{ code?: string }>;
          if (handeDefaultErrors(axiosError, t)) {
            return;
          }
          if (axiosError.response?.status === 400) {
            if (axiosError.response?.data.code === 'cannot_import_unvalidated_excel_file') {
              toast(t('excel-file.import.error.cannot_import_unvalidated_excel_file'), {
                type: 'error'
              });
              return;
            }
            if (axiosError.response?.data.code === 'cannot_import_invalid_excel_file') {
              toast(t('excel-file.import.error.cannot_import_invalid_excel_file'), {
                type: 'error'
              });
              return;
            }
            if (axiosError.response?.data.code === 'cannot_import_already_imported_excel_file') {
              toast(t('excel-file.import.error.cannot_import_already_imported_excel_file'), {
                type: 'error'
              });
              return;
            }
          }
        } else {
          toast(t('excel-file.import.error.unknown'), { type: 'error' });
        }
      }
    });
  }, [excelId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (status === 'loading') {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  if (status === 'error') {
    return (
      <Page>
        <div className="flex flex-row justify-between">
          <H1>{t('excel-file.import.title.display')}</H1>
          <div>
            <a className={DefaultLinkClass} onClick={() => router.history.back()}>
              {t('Back', { ns: 'common' })}
            </a>
          </div>
        </div>
      </Page>
    );
  }

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <H1>{t('excel-file.import.title.display')}</H1>
        <div>
          <a className={DefaultLinkClass} onClick={() => router.history.back()}>
            {t('Back', { ns: 'common' })}
          </a>
        </div>
      </div>
      <div>
        <p>{t('excel-file.import.success')}</p>
      </div>
    </Page>
  );
}
