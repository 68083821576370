import { ValidateExcelFileAPIResponse } from '@/services/api/excel.ts';
import { useTranslation } from 'react-i18next';
import { Fragment } from 'react';

function activityErrorToTranslatedString(
  error: string,
  t: (key: string, options?: Record<string, unknown>) => string
) {
  switch (error) {
    case 'unknown_molecule':
      return t('excel-file.validate.result.activity.error.unknown_molecule');
    case 'assay_name_missing':
      return t('excel-file.validate.result.activity.error.assay_name_missing');
    case 'target_name_missing':
      return t('excel-file.validate.result.activity.error.target_name_missing');
    case 'type_missing':
      return t('excel-file.validate.result.activity.error.type_missing');
    case 'relation_missing':
      return t('excel-file.validate.result.activity.error.relation_missing');
    case 'value_missing':
      return t('excel-file.validate.result.activity.error.value_missing');
    case 'unit_missing':
      return t('excel-file.validate.result.activity.error.unit_missing');
    case 'unit_invalid':
      return t('excel-file.validate.result.activity.error.unit_invalid');
    case 'std_type_missing':
      return t('excel-file.validate.result.activity.error.std_type_missing');
    case 'std_type_invalid':
      return t('excel-file.validate.result.activity.error.std_type_invalid');
    case 'publicity_invalid':
      return t('excel-file.validate.result.activity.error.publicity_invalid');
    case 'mol_id_missing':
      return t('excel-file.validate.result.activity.error.mol_id_missing');
    case 'relation_invalid':
      return t('excel-file.validate.result.activity.error.relation_invalid');
    case 'value_nan':
      return t('excel-file.validate.result.activity.error.value_nan');
    case 'n_nan':
      return t('excel-file.validate.result.activity.error.n_nan');
    case 'std_nan':
      return t('excel-file.validate.result.activity.error.std_nan');
    default:
      return error;
  }
}

function activityWarningToTranslatedString(
  warning: string,
  t: (key: string, options?: Record<string, unknown>) => string
) {
  switch (warning) {
    case 'changes_assay_privacy':
      return t('excel-file.validate.result.activity.warning.changes_assay_privacy');
    case 'changes_target_privacy':
      return t('excel-file.validate.result.activity.warning.changes_target_privacy');
    default:
      return warning;
  }
}

function generateActivityWarningsTranslated(
  activity: ValidateExcelFileAPIResponse['activities'][0],
  t: (key: string, options?: Record<string, unknown>) => string
) {
  const warnings: Array<string> = [];
  if (activity.assayId === null) {
    warnings.push(t('excel-file.validate.result.activity.warning.new_assay'));
  }
  if (activity.targetId === null) {
    warnings.push(t('excel-file.validate.result.activity.warning.new_target'));
  }
  return warnings.map((warning, index) => {
    return <li key={index}>{warning}</li>;
  });
}

export function ExcelFileValidationActivityResult({
  data
}: {
  data: ValidateExcelFileAPIResponse['activities'];
}) {
  const { t } = useTranslation('compounds');

  if (data.length === 0) {
    return <div>{t('excel-file.validate.result.no_activities')}</div>;
  }

  return (
    <table className="table table-auto w-full break-all">
      <thead>
        <tr className="border-b border-b-gray-200 border-t border-t-gray-400">
          <th className="px-2 text-left border-e border-s">
            {t('excel-file.validate.result.activity.index')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.valid')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.mol_id')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.assay_name')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.assay_description')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.relation')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.value')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.unit')}
          </th>
        </tr>
        <tr className="border-b border-gray-200">
          <th className="px-2 text-left border-e border-s"></th>
          <th className="px-2 text-left border-e"></th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.type')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.target_name')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.target_description')}
          </th>
          <th className="px-2 text-left border-e">{t('excel-file.validate.result.activity.n')}</th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.std')}
          </th>
          <th className="px-2 text-left border-e">
            {t('excel-file.validate.result.activity.stdType')}
          </th>
        </tr>
        <tr className="border-b border-gray-400">
          <th className="px-2 text-left border-e border-s">
            {t('excel-file.validate.result.activity.publicity')}
          </th>
          <th className="px-2 text-left border-e" colSpan={3}>
            {t('excel-file.validate.result.activity.credits')}
          </th>
          <th className="px-2 text-left border-e" colSpan={2}>
            {t('excel-file.validate.result.activity.warnings')}
          </th>
          <th className="px-2 text-left border-e" colSpan={2}>
            {t('excel-file.validate.result.activity.errors')}
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((activity, index) => {
          return (
            <Fragment key={index + activity.molId}>
              <tr className={'border-b border-gray-200' + (index % 2 == 0 ? ' bg-gray-50' : '')}>
                <td className="px-2 border-e border-s">{index + 1}</td>
                <td
                  className={
                    'px-2 border-e ' + (activity.valid ? 'text-success-600' : 'text-danger-600')
                  }>
                  {activity.valid
                    ? t('excel-file.validate.result.is_valid')
                    : t('excel-file.validate.result.is_invalid')}
                </td>
                <td className="px-2 border-e">{activity.molId}</td>
                <td className="px-2 border-e">{activity.assayName}</td>
                <td className="px-2 border-e">{activity.assayDescription}</td>
                <td className="px-2 border-x">{activity.relation}</td>
                <td className="px-2 border-e">{activity.value}</td>
                <td className="px-2 border-e">{activity.unit}</td>
              </tr>
              <tr className={'border-b border-gray-200' + (index % 2 == 0 ? ' bg-gray-50' : '')}>
                <td className="px-2 border-e border-s"></td>
                <td className="px-2 border-e"></td>
                <td className="px-2 border-e">{activity.type}</td>
                <td className="px-2 border-e">{activity.targetName}</td>
                <td className="px-2 border-e">{activity.targetDescription}</td>
                <td className="px-2 border-e">{activity.n}</td>
                <td className="px-2 border-e">{activity.std}</td>
                <td className="px-2 border-e">{activity.stdType}</td>
              </tr>
              <tr className={'border-b border-gray-400' + (index % 2 == 0 ? ' bg-gray-50' : '')}>
                <td className="px-2 border-e border-s align-text-top">{activity.publicity}</td>
                <td className="px-2 border-e align-text-top" colSpan={3}>
                  {activity.credits}
                </td>
                <td className="px-2 border-e align-text-top text-warning-700" colSpan={2}>
                  <ul className="list-disc list-inside">
                    {activity.warnings.map((warning, index) => {
                      return (
                        <li key={warning + index}>
                          {activityWarningToTranslatedString(warning, t)}
                        </li>
                      );
                    })}
                    {generateActivityWarningsTranslated(activity, t)}
                  </ul>
                </td>
                <td className="px-2 border-e align-text-top text-danger-600" colSpan={2}>
                  <ul className="list-disc list-inside">
                    {activity.errors.map((error, index) => {
                      return (
                        <li key={error + index}>{activityErrorToTranslatedString(error, t)}</li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}
