import { ReactNode } from 'react';
import { useFormikContext } from 'formik';
import StyledForm from '@/partials/form/styledForm.tsx';

export default function StyledFormikForm({
  children,
  styled = true,
  ...props
}: {
  children: ReactNode;
  styled?: boolean;
  [key: string]: unknown;
}) {
  const formikContext = useFormikContext();

  return (
    <StyledForm
      {...props}
      styled={styled}
      onSubmit={formikContext.handleSubmit}
      onReset={formikContext.handleReset}>
      {children}
    </StyledForm>
  );
}
