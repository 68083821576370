import { ReactNode } from 'react';

export default function Page({
  children,
  className = '',
  container = true
}: {
  children?: ReactNode;
  className?: string;
  container?: boolean;
}) {
  return (
    <main className={'p-4 flex-1 overflow-scroll ' + className}>
      <div className={'mx-auto min-h-full w-full ' + (container ? 'container ' : '')}>
        {children}
      </div>
    </main>
  );
}
