import { Formik, FormikHelpers } from 'formik';
import StyledFormikTextInput from '@/partials/form/styledFormikTextInput.tsx';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import StyledFormikButton from '@/partials/form/styledFormikButton.tsx';
import StyledFormikForm from '@/partials/form/styledFormikForm.tsx';
import StyledFormikFileUpload from '@/partials/form/styledFormikFileUpload.tsx';

export type ExcelFileFormValues = {
  file: File | null;
  filename: string;
};

export default function ExcelFileForm({
  onSubmit,
  buttonText
}: {
  onSubmit: (
    values: ExcelFileFormValues,
    formikHelpers: FormikHelpers<ExcelFileFormValues>
  ) => void;
  buttonText?: string;
}) {
  const { t } = useTranslation('compounds');
  if (!buttonText) {
    buttonText = t('Submit', { ns: 'common' });
  }

  const validationSchema = Yup.object().shape({
    file: Yup.mixed().required(t('errors.required', { ns: 'form' })),
    filename: Yup.string().required(t('errors.required', { ns: 'form' }))
  });

  return (
    <Formik
      initialValues={
        {
          file: null,
          filename: ''
        } as ExcelFileFormValues
      }
      onSubmit={onSubmit}
      validationSchema={validationSchema}>
      <StyledFormikForm>
        <StyledFormikFileUpload
          label={t('excel-file.form.file.label')}
          name="file"
          id="file"
          accept=".xlsx"
        />
        <StyledFormikTextInput
          label={t('excel-file.form.filename.label')}
          name="filename"
          id="filename"
          placeholder={t('excel-file.form.filename.placeholder')}
        />
        <div className="flex flex-row">
          <StyledFormikButton type="submit">{buttonText}</StyledFormikButton>
        </div>
      </StyledFormikForm>
    </Formik>
  );
}
