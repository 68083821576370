import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanEditWorkingGroupMember } from '@/common/auth-utils.ts';

export const Route = createFileRoute('/admin/working-group/member/$memberId/edit')({
  beforeLoad: ({ context }) => {
    if (!userCanEditWorkingGroupMember(context.auth.user)) {
      throw redirectToHome();
    }
  }
});
