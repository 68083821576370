import { Target, PaginatedObject, Visibility } from '@/common/types.ts';
import { PaginatedAPIResponse } from '@/services/api/types.ts';
import { AxiosInstance } from 'axios';
import { calculatedLimitOffset } from '@/services/api/utils.ts';

export type TargetListAPIResponse = PaginatedAPIResponse<{
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
}>;

export async function getTargets(
  axios: AxiosInstance,
  page: number,
  search: string,
  onlyOwned: boolean,
  { itemsPerPage }: { itemsPerPage?: number } = {}
): Promise<PaginatedObject<Target>> {
  const { limit, offset } = calculatedLimitOffset(page, itemsPerPage);
  const response = await axios.get<TargetListAPIResponse>('/targets/', {
    params: { limit, offset, q: search, onlyOwned: onlyOwned ? 'true' : 'false' }
  });
  return {
    count: response.data.count,
    hasNext: response.data.next !== null,
    hasPrevious: response.data.previous !== null,
    objects: response.data.results.map((target) => ({
      id: target.id,
      name: target.name,
      workingGroup: target.workingGroup,
      description: target.description,
      privacy: target.privacy as Visibility
    }))
  };
}

export type TargetAPIResponse = {
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
};

export async function getTarget(axios: AxiosInstance, id: number): Promise<Target> {
  const response = await axios.get<TargetAPIResponse>(`/targets/${id}/`);
  return {
    id: response.data.id,
    name: response.data.name,
    workingGroup: response.data.workingGroup,
    description: response.data.description,
    privacy: response.data.privacy as Visibility
  };
}

export type CreateTargetValues = {
  name: string;
  description: string;
};

export type CreateTargetAPIResponse = {
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
};

export async function createTarget(
  axios: AxiosInstance,
  values: CreateTargetValues
): Promise<Target> {
  const response = await axios.post<CreateTargetAPIResponse>('/targets/', values);
  return {
    id: response.data.id,
    name: response.data.name,
    workingGroup: response.data.workingGroup,
    description: response.data.description,
    privacy: response.data.privacy as Visibility
  };
}

export type UpdateTargetValues = {
  name: string;
  description: string;
};

export type UpdateTargetAPIResponse = {
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
};

export async function updateTarget(
  axios: AxiosInstance,
  id: number,
  values: UpdateTargetValues
): Promise<Target> {
  const response = await axios.put<UpdateTargetAPIResponse>(`/targets/${id}/`, values);
  return {
    id: response.data.id,
    name: response.data.name,
    workingGroup: response.data.workingGroup,
    description: response.data.description,
    privacy: response.data.privacy as Visibility
  };
}

export async function deleteTarget(axios: AxiosInstance, id: number): Promise<number> {
  await axios.delete(`/targets/${id}/`);
  return id;
}
