import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import de_common from './locales/de/common.json';
import de_authentication from './locales/de/authentication.json';
import de_navigation from './locales/de/navigation.json';
import en_common from './locales/en/common.json';
import en_authentication from './locales/en/authentication.json';
import en_navigation from './locales/en/navigation.json';
import de_compounds from './locales/de/compounds.json';
import en_compounds from './locales/en/compounds.json';
import de_form from './locales/de/form.json';
import en_form from './locales/en/form.json';
import de_search from './locales/de/search.json';
import en_search from './locales/en/search.json';
import de_workingGroup from './locales/de/working-group.json';
import en_workingGroup from './locales/en/working-group.json';
import de_settings from './locales/de/settings.json';
import en_settings from './locales/en/settings.json';

const resources = {
  de: {
    common: de_common,
    authentication: de_authentication,
    navigation: de_navigation,
    compounds: de_compounds,
    form: de_form,
    search: de_search,
    'working-group': de_workingGroup,
    settings: de_settings
  },
  en: {
    common: en_common,
    authentication: en_authentication,
    navigation: en_navigation,
    compounds: en_compounds,
    form: en_form,
    search: en_search,
    'working-group': en_workingGroup,
    settings: en_settings
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // detect user language
  .init({
    defaultNS: 'common',
    debug: import.meta.env.MODE === 'development',
    resources,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
