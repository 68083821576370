import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanAccessWorkingGroupPanel } from '@/common/auth-utils.ts';

export const Route = createFileRoute('/admin/working-group')({
  beforeLoad: ({ context }) => {
    if (!userCanAccessWorkingGroupPanel(context.auth.user)) {
      throw redirectToHome();
    }
  }
});
