import { createFileRoute } from '@tanstack/react-router';
import { URLSearchFilter } from '@/common/types.ts';

type URLSearchParams = {
  q: string;
  f: URLSearchFilter[];
  p: number;
};

export const Route = createFileRoute('/')({
  validateSearch: (search: Record<string, unknown>): URLSearchParams => {
    return {
      q: (search.q as string) || '',
      f: (search.f as URLSearchFilter[]) || [],
      p: (search.p as number) || 1
    };
  }
});
