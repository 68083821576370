import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  createTarget,
  CreateTargetValues,
  getTarget,
  getTargets,
  updateTarget,
  UpdateTargetValues
} from '@/services/api/target.ts';
import { useAxios } from '@/provider/axios.tsx';

export function useTargets(page: number, search: string, onlyOwned: boolean, itemsPerPage: number) {
  const { axios } = useAxios();
  return useQuery({
    queryKey: [
      'targets',
      { search: search, page: page, onlyOwned: onlyOwned, itemsPerPage: itemsPerPage },
      axios
    ],
    queryFn: () => getTargets(axios, page, search, onlyOwned, { itemsPerPage: itemsPerPage })
  });
}

export function useTarget(id: number) {
  const { axios } = useAxios();
  return useQuery({
    queryKey: ['targets', { id }, axios],
    queryFn: () => getTarget(axios, id)
  });
}

export function useCreateTarget() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (values: CreateTargetValues) => createTarget(axios, values),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['targets'] });
    }
  });
}

export function useUpdateTarget() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { id: number; values: UpdateTargetValues }) =>
      updateTarget(axios, data.id, data.values),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['targets'] });
    }
  });
}

export function useDeleteTarget() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => axios.delete(`/targets/${id}/`),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['targets'] });
    }
  });
}
