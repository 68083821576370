import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanAccessAssayPanel } from '@/common/auth-utils.ts';

type SearchParams = {
  search: string;
  page: number;
  onlyOwn: boolean;
};

export const Route = createFileRoute('/admin/assay')({
  beforeLoad: ({ context }) => {
    if (!userCanAccessAssayPanel(context.auth.user)) {
      throw redirectToHome();
    }
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      search: (search.search as string) || '',
      page: (search.page as number) || 1,
      onlyOwn: (search.onlyOwn as boolean) ?? true
    };
  }
});
