import { Assay, PaginatedObject, Visibility } from '@/common/types.ts';
import { PaginatedAPIResponse } from '@/services/api/types.ts';
import { AxiosInstance } from 'axios';
import { calculatedLimitOffset } from '@/services/api/utils.ts';

export type AssayListAPIResponse = PaginatedAPIResponse<{
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
}>;

export async function getAssays(
  axios: AxiosInstance,
  page: number,
  search: string,
  onlyOwned: boolean,
  { itemsPerPage }: { itemsPerPage?: number } = {}
): Promise<PaginatedObject<Assay>> {
  const { limit, offset } = calculatedLimitOffset(page, itemsPerPage);
  const response = await axios.get<AssayListAPIResponse>('/assays/', {
    params: { limit, offset, q: search, onlyOwned: onlyOwned ? 'true' : 'false' }
  });
  return {
    count: response.data.count,
    hasNext: response.data.next !== null,
    hasPrevious: response.data.previous !== null,
    objects: response.data.results.map((assay) => ({
      id: assay.id,
      name: assay.name,
      workingGroup: assay.workingGroup,
      description: assay.description,
      privacy: assay.privacy as Visibility
    }))
  };
}

export type AssayAPIResponse = {
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
};

export async function getAssay(axios: AxiosInstance, id: number): Promise<Assay> {
  const response = await axios.get<AssayAPIResponse>(`/assays/${id}/`);
  return {
    id: response.data.id,
    name: response.data.name,
    workingGroup: response.data.workingGroup,
    description: response.data.description,
    privacy: response.data.privacy as Visibility
  };
}

export type CreateAssayValues = {
  name: string;
  description: string;
};

export type CreateAssayAPIResponse = {
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
};

export async function createAssay(axios: AxiosInstance, values: CreateAssayValues): Promise<Assay> {
  const response = await axios.post<CreateAssayAPIResponse>('/assays/', values);
  return {
    id: response.data.id,
    name: response.data.name,
    workingGroup: response.data.workingGroup,
    description: response.data.description,
    privacy: response.data.privacy as Visibility
  };
}

export type UpdateAssayValues = {
  name: string;
  description: string;
};

export type UpdateAssayAPIResponse = {
  id: number;
  name: string;
  workingGroup: number;
  description: string;
  privacy: string;
};

export async function updateAssay(
  axios: AxiosInstance,
  id: number,
  values: UpdateAssayValues
): Promise<Assay> {
  const response = await axios.put<UpdateAssayAPIResponse>(`/assays/${id}/`, values);
  return {
    id: response.data.id,
    name: response.data.name,
    workingGroup: response.data.workingGroup,
    description: response.data.description,
    privacy: response.data.privacy as Visibility
  };
}

export async function deleteAssay(axios: AxiosInstance, id: number): Promise<number> {
  await axios.delete(`/assays/${id}/`);
  return id;
}
