import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { AuthContext } from '@/provider/auth.tsx';
import NavBar from '@/components/nav.tsx';
import { useTranslation } from 'react-i18next';
import Page from '@/partials/page/default.tsx';

interface RouterContext {
  auth: AuthContext;
}

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
  notFoundComponent: NotFound
});

function RootComponent() {
  const { t } = useTranslation('navigation');
  const dataPrivacyURL = (import.meta.env.VITE_DATA_PRIVACY_URL ?? '') as string;
  const imprintURL = (import.meta.env.VITE_IMPRINT_URL ?? '') as string;
  const thirdPartyLicensesURL = (import.meta.env.VITE_THIRD_PARTY_LICENSES_URL ?? '') as string;
  const hasAny =
    dataPrivacyURL.length > 0 || imprintURL.length > 0 || thirdPartyLicensesURL.length > 0;

  return (
    <div className="flex flex-col h-screen w-screen overflow-hidden">
      <NavBar />
      <Outlet />
      <div className="bg-gray-200 p-0.5 border-t border-gray-400 shadow-md flex justify-center">
        {hasAny && <span className="mx-2 text-gray-600">|</span>}
        {dataPrivacyURL.length > 0 && (
          <>
            <a href={dataPrivacyURL} className="text-gray-600 hover:text-gray-800">
              {t('Data Privacy')}
            </a>
            <span className="mx-2 text-gray-600">|</span>
          </>
        )}
        {imprintURL.length > 0 && (
          <>
            <a href={imprintURL} className="text-gray-600 hover:text-gray-800">
              {t('Imprint')}
            </a>
            <span className="mx-2 text-gray-600">|</span>
          </>
        )}
        {thirdPartyLicensesURL.length > 0 && (
          <>
            <a href={thirdPartyLicensesURL} className="text-gray-600 hover:text-gray-800">
              {t('Third Party Licenses')}
            </a>
            <span className="mx-2 text-gray-600">|</span>
          </>
        )}
      </div>
    </div>
  );
}

function NotFound() {
  const { t } = useTranslation('navigation');
  return <Page>{t('notFound')}</Page>;
}
