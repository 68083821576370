import { PaginatedAPIResponse } from '@/services/api/types.ts';
import { AxiosInstance } from 'axios';
import { ExcelFile, PaginatedObject, Relation, StdType, Visibility } from '@/common/types.ts';
import { calculatedLimitOffset } from '@/services/api/utils.ts';
import { SearchFilter } from '@/services/api/search.ts';

export type ExcelFileListAPIResponse = PaginatedAPIResponse<{
  id: number;
  fileName: string;
  validated: boolean;
  invalid: boolean | null;
  imported: boolean;
  hidden: boolean;
}>;

export async function getExcelFiles(
  axios: AxiosInstance,
  page: number,
  search: string,
  options?: { itemsPerPage?: number; hideHidden?: boolean }
): Promise<PaginatedObject<ExcelFile>> {
  const { itemsPerPage = 10 } = options || {};
  const { limit, offset } = calculatedLimitOffset(page, itemsPerPage);
  const response = await axios.get<ExcelFileListAPIResponse>('/excel-files/', {
    params: { limit, offset, q: search, hide_hidden: options?.hideHidden ?? true }
  });
  return {
    count: response.data.count,
    hasNext: response.data.next !== null,
    hasPrevious: response.data.previous !== null,
    objects: response.data.results.map((excelFile) => ({
      id: excelFile.id,
      fileName: excelFile.fileName,
      validated: excelFile.validated,
      invalid: excelFile.invalid,
      imported: excelFile.imported,
      hidden: excelFile.hidden
    }))
  };
}

export type ExcelFileAPIResponse = {
  id: number;
  fileName: string;
  validated: boolean;
  invalid: boolean | null;
  imported: boolean;
  hidden: boolean;
};

export async function getExcelFile(axios: AxiosInstance, id: number): Promise<ExcelFile> {
  const response = await axios.get<ExcelFileAPIResponse>(`/excel-files/${id}/`);
  return {
    id: response.data.id,
    fileName: response.data.fileName,
    validated: response.data.validated,
    invalid: response.data.invalid,
    imported: response.data.imported,
    hidden: response.data.hidden
  };
}

export async function uploadExcelFile(
  axios: AxiosInstance,
  file: File,
  filename: string
): Promise<{
  id: number;
}> {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('filename', filename);
  return (
    await axios.post<{
      id: number;
    }>('/excel-files/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  ).data;
}

export type ValidateExcelFileAPIResponse = {
  valid: boolean;
  compounds: Array<{
    valid: boolean;
    moleculeId: number | null;
    synonymId: number | null;
    structure: string;
    molId: string;
    errors: Array<'invalid_smiles' | 'missing_structure' | 'missing_mol_id'>;
  }>;
  activities: Array<{
    valid: boolean;
    molId: string;
    assayId: number | null;
    assayName: string;
    assayDescription: string;
    targetId: number | null;
    targetName: string;
    targetDescription: string;
    type: string;
    value: number;
    unit: string;
    relation: Relation;
    n: number | null;
    std: number | null;
    stdType: StdType | null;
    credits: string;
    publicity: Visibility;
    warnings: Array<'changes_assay_privacy' | 'changes_target_privacy'>;
    errors: Array<
      | 'unknown_molecule'
      | 'unknown_assay'
      | 'assay_name_missing'
      | 'target_name_missing'
      | 'unknown_target'
      | 'missing_value'
      | 'type_missing'
      | 'relation_missing'
      | 'value_missing'
      | 'unit_prefix_missing'
      | 'unit_missing'
      | 'unit_invalid'
      | 'std_type_missing'
      | 'std_type_invalid'
      | 'publicity_invalid'
      | 'mol_id_missing'
      | 'relation_invalid'
      | 'value_nan'
      | 'n_nan'
      | 'std_nan'
    >;
  }>;
};

export async function validateExcelFile(
  axios: AxiosInstance,
  id: number
): Promise<ValidateExcelFileAPIResponse> {
  return (await axios.post<ValidateExcelFileAPIResponse>(`/excel-files/${id}/validate/`)).data;
}

export async function importExcelFile(axios: AxiosInstance, id: number): Promise<void> {
  await axios.post(`/excel-files/${id}/import/`);
}

export async function toggleExcelFile(axios: AxiosInstance, id: number): Promise<void> {
  await axios.post(`/excel-files/${id}/toggle/`);
}

export async function deleteExcelFile(axios: AxiosInstance, id: number): Promise<void> {
  await axios.delete(`/excel-files/${id}/`);
}

export async function downloadExcelFile(axios: AxiosInstance, id: number): Promise<Blob> {
  return await axios
    .get(`/excel-files/${id}/download/`, {
      responseType: 'blob'
    })
    .then((resp) => resp.data);
}

export async function downloadExcelFileTemplate(axios: AxiosInstance): Promise<Blob> {
  return await axios
    .get(`/excel-files/template/`, {
      responseType: 'blob'
    })
    .then((resp) => resp.data);
}

export async function downloadExcelExportByCompoundId(
  axios: AxiosInstance,
  compoundIds: number[]
): Promise<Blob> {
  return await axios
    .get(`/export-excel/`, { responseType: 'blob', params: { compounds: compoundIds.join(',') } })
    .then((resp) => resp.data);
}

export async function downloadExcelExportBySearch(
  axios: AxiosInstance,
  query: string,
  filters: SearchFilter[]
): Promise<Blob> {
  return await axios
    .get(`/export-excel/`, {
      responseType: 'blob',
      params: { q: query, filters: JSON.stringify(filters) }
    })
    .then((resp) => resp.data);
}
