export default function StyledTextInput({
  ...props
}: {
  className?: string;
  [key: string]: unknown;
}) {
  const { className, ...rest } = props;
  const defaultClassName =
    'block w-full rounded-md bg-white border-0 py-1.5 pl-4 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm';

  return (
    <input
      className={className ? `${className} ${defaultClassName}` : defaultClassName}
      {...rest}
    />
  );
}
