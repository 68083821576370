import { createFileRoute, useRouter } from '@tanstack/react-router';
import Page from '@/partials/page/default.tsx';
import { redirectToHome, userCanDeleteTarget } from '@/common/auth-utils.ts';
import { useTranslation } from 'react-i18next';
import { useTarget, useDeleteTarget } from '@/services/query/target.ts';
import { toast } from 'react-toastify';
import { FormEvent, useEffect } from 'react';
import { Loading } from '@/partials/common/loading.tsx';
import H1 from '@/partials/common/h1.tsx';
import { DefaultLinkClass } from '@/partials/common/link.ts';
import StyledButton from '@/partials/form/styledButton.tsx';
import { AxiosError, isAxiosError } from 'axios';
import { handeDefaultErrors } from '@/services/query/utils.tsx';

export const Route = createFileRoute('/admin/target/$targetId/delete')({
  component: AdminTargetDelete,
  beforeLoad: ({ context }) => {
    if (!userCanDeleteTarget(context.auth.user)) {
      throw redirectToHome();
    }
  }
});

function AdminTargetDelete() {
  const { t } = useTranslation('compounds');
  const router = useRouter();
  const { targetId } = Route.useParams();

  const { status, data, error } = useTarget(parseInt(targetId, 10));
  const deleteTarget = useDeleteTarget();

  useEffect(() => {
    if (status === 'error') {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError<{ code?: string }>;
        if (handeDefaultErrors(axiosError, t)) {
          return;
        }
      }
      toast(t('target.detail.error.unknown'), { type: 'error' });
    }
  }, [status, error, t]);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (data) {
      deleteTarget.mutate(data.id, {
        onError: (error) => {
          if (isAxiosError(error)) {
            const axiosError = error as AxiosError<{ code?: string }>;
            if (handeDefaultErrors(axiosError, t)) {
              return;
            }
            if (axiosError.response?.status === 400) {
              if (axiosError.response?.data.code === 'cannot_delete_with_related_activities') {
                toast(t('target.delete.error.related_activities'), { type: 'error' });
                return;
              }
            }
          } else {
            toast(t('target.delete.error.unknown'), { type: 'error' });
          }
        },
        onSuccess: () => {
          console.debug('Target deleted');
          toast(t('target.delete.success'), { type: 'success' });
          router.history.push('/admin/target');
        }
      });
    }
  };

  if (status === 'pending') {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  if (status === 'error' || !data) {
    return <Page></Page>;
  }

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <H1>{t('target.delete.title.display')}</H1>
        <div>
          <a className={DefaultLinkClass} onClick={() => router.history.back()}>
            {t('Back', { ns: 'common' })}
          </a>
        </div>
      </div>
      <form onSubmit={handleFormSubmit}>
        <div className="py-2">{t('target.delete.confirmation', { name: data.name })}</div>
        <div className="flex flex-row gap-4">
          <StyledButton type="button" variant="secondary" onClick={() => router.history.back()}>
            {t('Cancel', { ns: 'common' })}
          </StyledButton>
          <StyledButton type="submit" variant="danger">
            {t('Delete', { ns: 'common' })}
          </StyledButton>
        </div>
      </form>
    </Page>
  );
}
