import { createFileRoute, Link, Outlet } from '@tanstack/react-router';
import {
  redirectToHome,
  userCanAccessActivityPanel,
  userCanAccessAdminPanel,
  userCanAccessAssayPanel,
  userCanAccessExcelFilePanel,
  userCanAccessTargetPanel,
  userCanAccessWorkingGroupPanel
} from '@/common/auth-utils.ts';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/provider/auth.tsx';

export const Route = createFileRoute('/admin')({
  component: Admin,
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated || !userCanAccessAdminPanel(context.auth.user)) {
      throw redirectToHome();
    }
  }
});

function Admin() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    <div className="flex flex-row h-full w-full overflow-hidden">
      <nav className="bg-gray-100 w-48 h-full py-4 px-2">
        <b>{t('Navigation', { ns: 'navigation' })}</b>
        <div className="flex flex-col">
          {userCanAccessWorkingGroupPanel(user) && (
            <Link
              to="/admin/working-group"
              search={{}}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={{
                className: 'underline'
              }}>
              {t('Working Group', { ns: 'navigation' })}
            </Link>
          )}
          {userCanAccessAssayPanel(user) && (
            <Link
              to="/admin/assay"
              search={{
                page: 1,
                search: '',
                onlyOwn: true
              }}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={{
                className: 'underline'
              }}>
              {t('Assays', { ns: 'navigation' })}
            </Link>
          )}
          {userCanAccessTargetPanel(user) && (
            <Link
              to="/admin/target"
              search={{
                page: 1,
                search: '',
                onlyOwn: true
              }}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={{
                className: 'underline'
              }}>
              {t('Targets', { ns: 'navigation' })}
            </Link>
          )}
          {userCanAccessActivityPanel(user) && (
            <Link
              to="/admin/compound"
              search={{ page: 1, search: '' }}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={{
                className: 'underline'
              }}>
              {t('Compound', { ns: 'navigation' })}
            </Link>
          )}
          {userCanAccessActivityPanel(user) && (
            <Link
              to="/admin/activity"
              search={{ page: 1, search: '', onlyOwn: true }}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={{
                className: 'underline'
              }}>
              {t('Activities', { ns: 'navigation' })}
            </Link>
          )}
          {userCanAccessExcelFilePanel(user) && (
            <Link
              to="/admin/excel"
              search={{
                page: 1,
                search: '',
                showHidden: false
              }}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={{
                className: 'underline'
              }}>
              {t('Excel Files', { ns: 'navigation' })}
            </Link>
          )}
        </div>
      </nav>
      <Outlet />
    </div>
  );
}
