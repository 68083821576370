import { useAxios } from '@/provider/axios.tsx';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteExcelFile,
  getExcelFile,
  getExcelFiles,
  importExcelFile,
  toggleExcelFile,
  uploadExcelFile,
  validateExcelFile
} from '@/services/api/excel.ts';

export function useExcelFiles(
  page: number,
  search: string,
  options?: { itemsPerPage?: number; showHidden?: boolean }
) {
  const { axios } = useAxios();
  return useQuery({
    queryKey: [
      'excelFiles',
      {
        search: search,
        page: page,
        itemsPerPage: options?.itemsPerPage,
        showHidden: options?.showHidden
      },
      axios
    ],
    queryFn: () =>
      getExcelFiles(axios, page, search, {
        itemsPerPage: options?.itemsPerPage,
        hideHidden: !options?.showHidden
      })
  });
}

export function useExcelFile(id: number) {
  const { axios } = useAxios();
  return useQuery({
    queryKey: ['excelFiles', { id }, axios],
    queryFn: () => getExcelFile(axios, id)
  });
}

export function useCreateExcelFile() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { file: File; filename: string }) =>
      uploadExcelFile(axios, data.file, data.filename),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['excelFiles'] });
    }
  });
}

export function useDeleteExcelFile() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => deleteExcelFile(axios, id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['excelFiles'] });
    }
  });
}

export function useValidateExcelFile() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => validateExcelFile(axios, id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['excelFiles'] });
    },
    retry: false
  });
}

export function useImportExcelFile() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => importExcelFile(axios, id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['excelFiles'] });
    },
    retry: false
  });
}

export function useToggleExcelFile() {
  const { axios } = useAxios();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => toggleExcelFile(axios, id),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ['excelFiles'] });
    },
    retry: false
  });
}
