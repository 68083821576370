import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { useAxios } from '@/provider/axios.tsx';
import { flushSync } from 'react-dom';
import { useAuth } from '@/provider/auth.tsx';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Formik, FormikHelpers } from 'formik';
import Page from '@/partials/page/default.tsx';
import H1 from '@/partials/common/h1.tsx';
import { getUser, login } from '@/services/api/auth.ts';
import StyledFormikTextInput from '@/partials/form/styledFormikTextInput.tsx';
import StyledFormikForm from '@/partials/form/styledFormikForm.tsx';
import StyledFormikButton from '@/partials/form/styledFormikButton.tsx';
import { redirectToHome } from '@/common/auth-utils.ts';

type LoginSearch = {
  redirect: string;
};

export const Route = createFileRoute('/login')({
  validateSearch: (input: Record<string, unknown>): LoginSearch => {
    return {
      redirect: (input.redirect as string) ?? '/'
    };
  },
  component: Login,
  beforeLoad: ({ context }) => {
    if (context.auth.isAuthenticated) {
      throw redirectToHome();
    }
  }
});

function Login() {
  const { t } = useTranslation('authentication');

  const { axios, setAuthToken } = useAxios();
  const auth = useAuth();
  const navigate = useNavigate();
  const { redirect } = Route.useSearch();

  const handleSubmit = useCallback(
    (
      email: string,
      password: string,
      formikHelpers: FormikHelpers<{
        email: string;
        password: string;
      }>
    ) => {
      login(axios, { email, password })
        .then(({ token }) => {
          getUser(axios, token)
            .then((user) => {
              console.debug('User logged in', user);
              flushSync(() => {
                auth.setUser(user);
                setAuthToken(token);
                const cookies = new Cookies();
                cookies.set('token', token, { path: '/', sameSite: 'strict' });
              });

              void navigate({ to: redirect });
            })
            .catch((error) => {
              if (isAxiosError(error) && error.response) {
                console.error('Failed to get user information', error.response.data);
              }
              toast(t('login.error.failed_fetch_user.toast'), { type: 'error' });
              formikHelpers.setSubmitting(false);
            });
        })
        .catch((error) => {
          if (isAxiosError(error) && error.response) {
            console.error('Failed to login', error.response.data);
          }
          toast(t('login.error.invalid_credentials.toast'), { type: 'error' });
          formikHelpers.setSubmitting(false);
        });
    },
    [auth, axios, navigate, redirect, setAuthToken, t]
  );

  return (
    <Page>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        onSubmit={(values, formikHelpers) => {
          handleSubmit(values.email, values.password, formikHelpers);
        }}>
        <StyledFormikForm>
          <H1>{t('login.header.display')}</H1>
          <StyledFormikTextInput
            label={t('login.form.labels.email')}
            name={'email'}
            id={'email'}
            placeholder={t('login.form.labels.email')}
          />
          <StyledFormikTextInput
            label={t('login.form.labels.password')}
            placeholder={t('login.form.labels.password')}
            name={'password'}
            id={'password'}
            type="password"
          />
          <div>
            <StyledFormikButton type="submit">{t('login.form.buttons.login')}</StyledFormikButton>
          </div>
        </StyledFormikForm>
      </Formik>
    </Page>
  );
}
