import { createFileRoute } from '@tanstack/react-router';
import { redirectToHome, userCanAccessCompoundPanel } from '@/common/auth-utils.ts';

type SearchParams = {
  search: string;
  page: number;
};

export const Route = createFileRoute('/admin/compound')({
  beforeLoad: ({ context }) => {
    if (!context.auth.isAuthenticated || !userCanAccessCompoundPanel(context.auth.user)) {
      throw redirectToHome();
    }
  },
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    return {
      search: (search.search as string) || '',
      page: (search.page as number) || 1
    };
  }
});
