import { createFileRoute, useRouter } from '@tanstack/react-router';
import Page from '@/partials/page/default.tsx';
import { redirectToHome, userCanEditTarget } from '@/common/auth-utils.ts';
import { useTranslation } from 'react-i18next';
import { useTarget, useUpdateTarget } from '@/services/query/target.ts';
import TargetForm, { TargetFormValues } from '@/components/forms/target-form.tsx';
import { FormikHelpers } from 'formik';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { Loading } from '@/partials/common/loading.tsx';
import H1 from '@/partials/common/h1.tsx';
import { DefaultLinkClass } from '@/partials/common/link.ts';
import { AxiosError, isAxiosError } from 'axios';
import { handeDefaultErrors } from '@/services/query/utils.tsx';

export const Route = createFileRoute('/admin/target/$targetId/edit')({
  component: AdminTargetEdit,
  beforeLoad: ({ context }) => {
    if (!userCanEditTarget(context.auth.user)) {
      throw redirectToHome();
    }
  }
});

function AdminTargetEdit() {
  const { t } = useTranslation('compounds');
  const router = useRouter();
  const { targetId } = Route.useParams();

  const { status, data, error } = useTarget(parseInt(targetId, 10));
  const updateTarget = useUpdateTarget();

  useEffect(() => {
    if (status === 'error') {
      if (isAxiosError(error)) {
        const axiosError = error as AxiosError<{ code?: string }>;
        if (handeDefaultErrors(axiosError, t)) {
          return;
        }
      }
      toast(t('target.detail.error.unknown'), { type: 'error' });
    }
  }, [status, error, t]);

  const handleFormSubmit = (
    values: TargetFormValues,
    { setSubmitting }: FormikHelpers<TargetFormValues>
  ) => {
    if (data) {
      updateTarget.mutate(
        { id: data.id, values },
        {
          onError: () => {
            if (isAxiosError(error)) {
              const axiosError = error as AxiosError<{ code?: string }>;
              if (handeDefaultErrors(axiosError, t)) {
                return;
              }
            }
            toast(t('target.update.error.unknown'), { type: 'error' });
            setSubmitting(false);
          },
          onSuccess: () => {
            console.debug('Target updated');
            toast(t('target.update.success'), { type: 'success' });
            router.history.push('/admin/target');
          }
        }
      );
    }
  };

  if (status === 'pending') {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  if (status === 'error' || !data) {
    return <Page></Page>;
  }

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <H1>{t('target.update.title.display')}</H1>
        <div>
          <a className={DefaultLinkClass} onClick={() => router.history.back()}>
            {t('Back', { ns: 'common' })}
          </a>
        </div>
      </div>
      <TargetForm
        onSubmit={handleFormSubmit}
        buttonText={t('Update', { ns: 'common' })}
        initialValues={{
          name: data.name,
          description: data.description
        }}
      />
    </Page>
  );
}
