import { Formik, FormikHelpers } from 'formik';
import StyledFormikTextInput from '@/partials/form/styledFormikTextInput.tsx';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import StyledFormikButton from '@/partials/form/styledFormikButton.tsx';
import StyledFormikForm from '@/partials/form/styledFormikForm.tsx';

export type TargetFormValues = {
  name: string;
  description: string;
};

export default function TargetForm({
  initialValues = {
    name: '',
    description: ''
  },
  onSubmit,
  buttonText
}: {
  initialValues?: TargetFormValues;
  onSubmit: (values: TargetFormValues, formikHelpers: FormikHelpers<TargetFormValues>) => void;
  buttonText?: string;
}) {
  const { t } = useTranslation('compounds');
  if (!buttonText) {
    buttonText = t('Submit', { ns: 'common' });
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('errors.required', { ns: 'form' })),
    description: Yup.string().required(t('errors.required', { ns: 'form' }))
  });

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      <StyledFormikForm>
        <StyledFormikTextInput
          label={t('target.form.name.label')}
          name="name"
          id="name"
          placeholder={t('target.form.name.placeholder')}
        />
        <StyledFormikTextInput
          label={t('target.form.description.label')}
          name="description"
          id="description"
          placeholder={t('target.form.description.placeholder')}
        />
        <div className="flex flex-row">
          <StyledFormikButton type="submit">{buttonText}</StyledFormikButton>
        </div>
      </StyledFormikForm>
    </Formik>
  );
}
