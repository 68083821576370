import { ITEMS_PER_PAGE } from '@/conf.ts';

export function calculatedLimitOffset(
  page: number,
  itemsPerPage: number = ITEMS_PER_PAGE
): { limit: number; offset: number } {
  return {
    limit: itemsPerPage,
    offset: (page - 1) * itemsPerPage
  };
}
