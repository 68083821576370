import { createFileRoute, Link, useRouter } from '@tanstack/react-router';
import { useEffect, useState } from 'react';
import { Loading } from '@/partials/common/loading.tsx';
import { redirectToHome, userCanValidateExcelFile } from '@/common/auth-utils.ts';
import Page from '@/partials/page/default.tsx';
import { useValidateExcelFile } from '@/services/query/excel.ts';
import H1 from '@/partials/common/h1.tsx';
import { DefaultLinkClass } from '@/partials/common/link.ts';
import { useTranslation } from 'react-i18next';
import { AxiosError, isAxiosError } from 'axios';
import { handeDefaultErrors } from '@/services/query/utils.tsx';
import { toast } from 'react-toastify';
import { ExcelFileValidationCompoundResult } from '@/partials/objects/excel-file-validation-compound-result.tsx';
import StyledButton from '@/partials/form/styledButton.tsx';
import { ExcelFileValidationActivityResult } from '@/partials/objects/excel-file-validation-activity-result.tsx';
import { ValidateExcelFileAPIResponse } from '@/services/api/excel.ts';

export const Route = createFileRoute('/admin/excel/$excelId/validate')({
  component: AdminExcelValidate,
  beforeLoad: ({ context }) => {
    if (!userCanValidateExcelFile(context.auth.user)) {
      throw redirectToHome();
    }
  }
});

function AdminExcelValidate() {
  const { excelId } = Route.useParams();
  const router = useRouter();
  const { t } = useTranslation('compounds');

  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<ValidateExcelFileAPIResponse | null>(null);

  const validate = useValidateExcelFile();

  useEffect(() => {
    validate.mutate(parseInt(excelId, 10), {
      onSuccess: (data) => {
        setResult(data);
      },
      onError: (error) => {
        if (isAxiosError(error)) {
          const axiosError = error as AxiosError<{ code?: string }>;
          if (handeDefaultErrors(axiosError, t)) {
            return;
          }
          if (axiosError.response?.status === 400) {
            if (axiosError.response?.data.code === 'cannot_read_excel_file') {
              toast(t('excel-file.validate.error.cannot_read_excel_file'), { type: 'error' });
              setError(t('excel-file.validate.error.cannot_read_excel_file'));
              return;
            }
            if (axiosError.response?.data.code === 'cannot_validate_imported_excel_file') {
              toast(t('excel-file.validate.error.cannot_validate_imported_excel_file'), {
                type: 'error'
              });
              setError(t('excel-file.validate.error.cannot_validate_imported_excel_file'));
              return;
            }
            if (axiosError.response?.data.code === 'missing_header') {
              toast(t('excel-file.validate.error.missing_header'), { type: 'error' });
              setError(t('excel-file.validate.error.missing_header'));
              return;
            }
            if (axiosError.response?.data.code === 'missing_sheets') {
              toast(t('excel-file.validate.error.missing_sheets'), { type: 'error' });
              setError(t('excel-file.validate.error.missing_sheets'));
              return;
            }
            if (axiosError.response?.data.code === 'invalid_file') {
              toast(t('excel-file.validate.error.invalid_file'), { type: 'error' });
              setError(t('excel-file.validate.error.invalid_file'));
              return;
            }
          }
        }
        toast(t('excel-file.validate.error.unknown'), { type: 'error' });
        setError(t('excel-file.validate.error.unknown'));
      }
    });
  }, [excelId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (error !== null) {
    return (
      <Page>
        <div className="flex flex-row justify-between">
          <H1>{t('excel-file.validate.title.display')}</H1>
          <div>
            <a className={DefaultLinkClass} onClick={() => router.history.back()}>
              {t('Back', { ns: 'common' })}
            </a>
          </div>
        </div>
        <div>{error}</div>
      </Page>
    );
  }

  if (result === null) {
    return (
      <Page>
        <Loading />
      </Page>
    );
  }

  return (
    <Page>
      <div className="flex flex-row justify-between">
        <H1>{t('excel-file.validate.title.display')}</H1>
        <div>
          <a className={DefaultLinkClass} onClick={() => router.history.back()}>
            {t('Back', { ns: 'common' })}
          </a>
        </div>
      </div>
      <div>
        <div>
          <b>{t('excel-file.validate.result.is_valid')}:</b>&nbsp;
          <span>{result.valid ? t('Yes', { ns: 'common' }) : t('No', { ns: 'common' })}</span>
        </div>
        <div className="mt-4">
          <b>{t('excel-file.validate.result.compounds')}:</b>&nbsp;
          <ExcelFileValidationCompoundResult data={result.compounds} />
        </div>
        <div className="mt-4">
          <b>{t('excel-file.validate.result.activities')}:</b>&nbsp;
          <ExcelFileValidationActivityResult data={result.activities} />
        </div>
      </div>
      {result.valid && (
        <div className="mt-3">
          <Link search={{}} to={`/admin/excel/${excelId}/import`} params={{ excelId: excelId }}>
            <StyledButton>{t('excel-file.validate.import')}</StyledButton>
          </Link>
        </div>
      )}
    </Page>
  );
}
