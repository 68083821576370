import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18n'; // Language Support
import { createRouter, RouterProvider } from '@tanstack/react-router';
import { AuthProvider, useAuth } from './provider/auth.tsx';
import { AxiosProvider } from '@/provider/axios.tsx';
import { User } from '@/common/types.ts';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { ToastContainer, Bounce } from 'react-toastify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Import the generated route tree
import { routeTree } from './routeTree.gen';

// Styles
import 'react-toastify/dist/ReactToastify.min.css';
import './index.scss';
import { Loading } from '@/partials/common/loading.tsx';

// Create a new router instance
const router = createRouter({
  routeTree,
  defaultPreload: 'intent',
  context: {
    auth: undefined!
  }
});

// eslint-disable-next-line react-refresh/only-export-components
const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools
        }))
      );

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

// eslint-disable-next-line react-refresh/only-export-components
function App() {
  const auth = useAuth();
  return (
    <Suspense fallback={<Loading />}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      <RouterProvider router={router} context={{ auth }} />
      <TanStackRouterDevtools position="bottom-right" initialIsOpen={false} router={router} />
      <ReactQueryDevtools buttonPosition="bottom-left" initialIsOpen={false} />
    </Suspense>
  );
}

async function boot(): Promise<{
  initialUser: User | null;
  initialToken: string | null;
}> {
  // Check if the user is already authenticated
  const cookies = new Cookies();
  const token = cookies.get('token');
  if (token) {
    // Check if token is valid
    try {
      const loginResponse = await axios.get(`/auth/me/`, {
        headers: {
          Authorization: `Token ${token}`
        },
        baseURL: import.meta.env.VITE_API_URL
      });
      console.debug('User is already authenticated');
      console.debug(loginResponse.data);
      let workingGroup = null;
      try {
        const workingGroupResponse = await axios.get(`/auth/working-group/`, {
          headers: {
            Authorization: `Token ${token}`
          },
          baseURL: import.meta.env.VITE_API_URL
        });
        workingGroup = {
          id: workingGroupResponse.data.id,
          name: workingGroupResponse.data.name
        };
      } catch (err) {
        // pass
      }
      console.debug('Working group:', workingGroup);
      return {
        initialUser: {
          username: loginResponse.data.username,
          email: loginResponse.data.email,
          id: loginResponse.data.id,
          permissions: loginResponse.data.permissions,
          workingGroup: workingGroup
        },
        initialToken: token
      };
    } catch (err) {
      // If the token is invalid, remove it
      cookies.remove('token');
    }
  }

  return {
    initialUser: null,
    initialToken: null
  };
}

// Create a client
const queryClient = new QueryClient();

boot().then(({ initialUser, initialToken }) => {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <AxiosProvider initialAuthToken={initialToken}>
        <AuthProvider initialUser={initialUser}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </AuthProvider>
      </AxiosProvider>
    </React.StrictMode>
  );
});
