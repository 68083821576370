import React from 'react';
import axios, { AxiosInstance, isAxiosError } from 'axios';
import Cookies from 'universal-cookie';
import { redirect } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

export interface AxiosContext {
  axios: AxiosInstance;
  setAuthToken: (token: string | null) => void;
  authToken: string | null;
}

const AxiosContext = React.createContext<AxiosContext | null>(null);

export function AxiosProvider({
  children,
  initialAuthToken
}: {
  children: React.ReactNode;
  initialAuthToken: string | null;
}) {
  const [authToken, setAuthToken] = React.useState<string | null>(initialAuthToken);
  const { i18n } = useTranslation();

  const config: { [x: string]: Record<string, unknown> } = {
    baseURL: import.meta.env.VITE_API_URL,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.language ?? 'en'
    }
  };

  if (authToken) {
    config.headers['Authorization'] = `Token ${authToken}`;
  }

  const instance = axios.create(config);

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // TODO: Check if this is the correct check
      if (isAxiosError(error) && error.response && error.response.status === 401) {
        console.error('Unauthorized');
        const cookie = new Cookies();
        cookie.remove('token');
        throw redirect({
          to: '/login',
          search: { redirect: window.location.href }
        });
      }

      return Promise.reject(error);
    }
  );

  return (
    <AxiosContext.Provider value={{ axios: instance, setAuthToken, authToken }}>
      {children}
    </AxiosContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAxios() {
  const context = React.useContext(AxiosContext);
  if (!context) {
    throw new Error('useAxios must be used within an AxiosProvider');
  }
  return context;
}
