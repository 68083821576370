import { createFileRoute } from '@tanstack/react-router';
import Page from '@/partials/page/default.tsx';

export const Route = createFileRoute('/admin/')({
  component: AdminIndex
});

function AdminIndex() {
  return <Page></Page>;
}
